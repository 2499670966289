import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { 
        GET_EWALLET_SETTING_DETAILS,
     } = URLS;
    return {
        getWalletDetails: (body = {}, method = 'get', query) =>
            performRequest(method ? method : 'get', GET_EWALLET_SETTING_DETAILS, body, 'agent'),
    }
}




