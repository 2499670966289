import * as Types from '../../actions/type'
const initialState = {
    loading: false,
    loaded: false,
    data: [],
    params: null,
    allData: [],
}

const dummyRoomNumber = {rooms: [],
    page:1,
    pages:1,
    limit: 125,
    total: 0,
    allData: []
}

const getIndex = (arr, arr2, arr3, params = {}) => {
    if (arr2.length > 0) {
      let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
      let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
      let finalArr = [startIndex, endIndex]
      return (arr3 = finalArr)
    } else {
      let finalArr = [params.page, params.pages]
      return (arr3 = finalArr)
    }
}

export const roomNumberDetails = ( state=initialState, action) =>{
    switch (action.type){
        case Types.GET_ROOMNUMBER_DETAILS:
            return { ...state, loading: true };
        case Types.GET_ROOMNUMBER_DETAILS_SUCCESS: 
        let newData = {
        }
        if(Object.keys(action.payload).length > 0){
            newData = { ...action.payload};
             action.payload.rooms.map((i)=>(i.roomNumber=parseInt(i.roomNumber)))
            newData.allData = [...action.payload.rooms]
        }else{
            newData = {...dummyRoomNumber}
        }
        newData.sortIndex = getIndex(newData.allData, state.data, state.sortIndex, {...newData})
        return { ...state, loading: false, loaded: true, payload: newData }
        case Types.GET_ROOMNUMBER_DETAILS_FAILURE:
            return { ...state, loading: false, loaded: false, error: action.payload }
        case Types.ADD_ROOMNUMBER_DATA: 
            let updatedData = { ...state.payload}
            updatedData.rooms.unshift({...action.payload});
            updatedData.allData = [...updatedData.rooms];
            updatedData.total = updatedData.rooms.length;
            updatedData.sortIndex = getIndex(updatedData.rooms, 
                                            updatedData.allData, 
                                            state.payload.sortIndex, 
                                            {...updatedData})
            return { ...state, loading: false, loaded: true, payload: updatedData}
        case Types.UPDATE_ROOMNUMBER_DATA:
            let updatingData = { ...state.payload}
            
            let newRoomNUmbers = updatingData.rooms.map((item) =>{
                if(item._id === action.payload._id){
                    item = {...action.payload}
                }
                return item
            })
            updatingData.rooms = [...newRoomNUmbers];
            return {
                ...state, loading: false, loaded: true, payload: {...updatingData}
            }
        case Types.DELETE_ROOMNUMBER_DATA:
            let deleteData = { ...state.payload}
            let deleteingRoomNUmbers = deleteData.rooms.filter((item) =>{
                return item._id !== action.payload._id;
            })
            deleteData.rooms = [...deleteingRoomNUmbers];
            deleteData.allData = [...deleteingRoomNUmbers];
            deleteData.total = deleteData.rooms.length;
            deleteData.sortIndex = getIndex(deleteData.rooms, 
                                            deleteData.allData, 
                                            state.payload.sortIndex, 
                                            {...deleteData})
            return {
                ...state, loading: false, loaded: true, payload: {...deleteData}
            }
        default: 
            return state
    }
}



