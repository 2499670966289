import { reservationService } from '../../../service';
import * as Types from "../type";

const initialize = () => ({
    type: Types.GET_WALLET_DETAILS
})

const messageSuccess = data =>({
    type: Types.GET_WALLET_DETAILS_SUCCESS,
    payload: {
        ...data
    }
});



const messageFailure = error => ({
    type: Types.GET_WALLET_DETAILS_FAILURE,
    payload: {
        error
    }
});



export function getWalletInfo(id) {
    return dispatch =>{
        dispatch(initialize());
        reservationService()
        .getWallet()
        .then(res =>{
                dispatch(messageSuccess(res.data));
            })
        .catch(err =>{
            
            dispatch(messageFailure(err));
        });
    };
}






