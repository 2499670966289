import * as Types from '../../actions/type'
const initialState = {
    loading: false,
    loaded: false,
    data: [],
    params: null,
    allData: [],
}

const dummyFacility = {
    facilities: [],
    page: 1,
    pages: 1,
    limit: 10,
    total: 0,
    allData: []
}

const getIndex = (arr, arr2, arr3, params = {}) => {
    if (arr2.length > 0) {
        let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
        let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
        let finalArr = [startIndex, endIndex]
        return (arr3 = finalArr)
    } else {
        let finalArr = [params.page, params.pages]
        return (arr3 = finalArr)
    }
}

export const facilityDetails = (state = initialState, action) => {
    switch (action.type) {
        case Types.GET_FACILITY_DETAILS:
            return { ...state, loading: true };
        case Types.GET_FACILITY_DETAILS_SUCCESS:
            let newData = {
            }
            if (Object.keys(action.payload).length > 0) {
                newData = { ...action.payload };
                newData.allData = [...action.payload.facilities]
            } else {
                newData = { ...dummyFacility }
            }
            newData.sortIndex = getIndex(newData.allData, state.data, state.sortIndex, { ...newData })
            return { ...state, loading: false, loaded: true, payload: newData }
        case Types.GET_FACILITY_DETAILS_FAILURE:
            return { ...state, loading: false, loaded: false, error: action.payload }
        case Types.ADD_FACILITY_DATA:
            let updatedData = { ...state.payload }
            updatedData.facilities.unshift({ ...action.payload });
            updatedData.allData = [...updatedData.facilities];
            updatedData.total = updatedData.facilities.length;
            updatedData.sortIndex = getIndex(updatedData.facilities,
                updatedData.allData,
                state.payload.sortIndex,
                { ...updatedData })
            return { ...state, loading: false, loaded: true, payload: updatedData }
        case Types.UPDATE_FACILITY_DATA:
            let updatingData = { ...state.payload }

            let newFacility = updatingData.facilities.map((item) => {
                if (item._id === action.payload._id) {
                    item = { ...action.payload }
                }
                return item
            })
            updatingData.facilities = [...newFacility];
            return {
                ...state, loading: false, loaded: true, payload: { ...updatingData }
            }
        case Types.DELETE_FACILITY_DATA:
            let deleteData = { ...state.payload }
            let deleteingFacilities = deleteData.facilities.filter((item) => {
                return item._id !== action.payload._id;
            })
            deleteData.facilities = [...deleteingFacilities];
            deleteData.allData = [...deleteingFacilities];
            deleteData.total = deleteData.facilities.length;
            deleteData.sortIndex = getIndex(deleteData.facilities,
                deleteData.allData,
                state.payload.sortIndex,
                { ...deleteData })
            return {
                ...state, loading: false, loaded: true, payload: { ...deleteData }
            }
        default:
            return state
    }
}



