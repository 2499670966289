import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_ADDON_SUB_CATEGORY_DETAILS,
        ADD_ADDON_SUB_CATEGORY_DETAILS,
        UPDATE_ADDON_SUB_CATEGORY_DETAILS,
        DELETE_ADDON_SUB_CATEGORY_DETAILS } = URLS;
    return {
        getAddonSubCategoryDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_ADDON_SUB_CATEGORY_DETAILS, body),
        addAddonSubCategoryDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_ADDON_SUB_CATEGORY_DETAILS, body),
        updateAddonSubCategoryDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_ADDON_SUB_CATEGORY_DETAILS, body),
        deleteAddonSubCategoryDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_ADDON_SUB_CATEGORY_DETAILS, body),

        getAddonCategorySubcategoryDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', `/addon-category/${body}/addon-sub-categories`),


    }
}