import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_TYPEOFPAX_SETTING_DETAILS, ADD_TYPEOFPAX_SETTING_DETAILS, UPDATE_TYPEOFPAX_SETTING_DETAILS} = URLS;
    return {
        getTypeOfPaxDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_TYPEOFPAX_SETTING_DETAILS, body),
        addTypeOfPaxDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_TYPEOFPAX_SETTING_DETAILS, body),
        updateTypeOfPaxDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_TYPEOFPAX_SETTING_DETAILS, body)
    }
}
