import * as Types from '../../actions/type'
const initialState = {
    loading: false,
    loaded: false,
    data: [],
    params: null,
    allData: [],
}

const dummybookingListing = {listing: [],
    page:1,
    pages:1,
    limit: 10,
    total: 0,
    allData: []
}

const getIndex = (arr, arr2, arr3, params = {}) => {
    if (arr2.length > 0) {
      let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
      let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
      let finalArr = [startIndex, endIndex]
      return (arr3 = finalArr)
    } else {
      let finalArr = [params.page, params.pages]
      return (arr3 = finalArr)
    }
}

export const bookingListingDetails = ( state=initialState, action) =>{
    switch (action.type){
        case Types.GET_BOOKING_LISTING_DETAILS:
            return { ...state, loading: true };
        case Types.GET_BOOKING_LISTING_DETAILS_SUCCESS: 
        let newData = {
        }
        if(Object.keys(action.payload).length > 0){
            newData = { ...action.payload};
            newData.allData = [...action.payload.reservations]
        }else{
            newData = {...dummybookingListing}
        }
        newData.sortIndex = getIndex(newData.allData, state.data, state.sortIndex, {...newData})
        return { ...state, loading: false, loaded: true, payload: newData }
        case Types.GET_BOOKING_LISTING_DETAILS_FAILURE:
            return { ...state, loading: false, loaded: false, error: action.payload }
        default: 
            return state
    }
}



