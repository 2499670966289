import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_AGENT_PROFILE_DETAILS, 
            GET_AGENT_DETAILS,
            ADD_AGENT_PROFILE_DETAILS,
            UPDATE_AGENT_PROFILE_DETAILS,
            AGENT_FILE_UPLOAD,
            GET_AGENT_DOCUMENTS,
            UPDATE_PASSWORD,
            UPDATE_FREE_LANCER_PROFILE,
            GET_SOCIAL_SHARE_DETAILS
         } = URLS;
    return {
        getAgentProfileDetails: (body={}, method='get', url) =>
         performRequest( method ? method:'get', url ||GET_AGENT_PROFILE_DETAILS.replace(":_id",body._id), body, "agent" ),
        addAgentProfileDetail: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_AGENT_PROFILE_DETAILS, body ),
         updatAgentProfileDetail: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_AGENT_PROFILE_DETAILS.replace(":mainId",body.userId), body, "agent" ),
         uploadAgentImage: (body={}, method='post') =>
         performRequest( method ? method:'put', AGENT_FILE_UPLOAD, body ),
        getAgentData:(body={}, method='get', url) =>
            performRequest( method ? method:'get', url || GET_AGENT_DETAILS, body ),
        getAgentDocuments:(body={}, method='get', url) =>
            performRequest( method ? method:'get', url || GET_AGENT_DOCUMENTS.replace(":userId", body.id), body, "agent" ),
        updatePassword: (body={}, method='put') =>
            performRequest( method ? method:'put', UPDATE_PASSWORD, body, "agent" ),
        updateFreeLanceProfile: (body={}, method='put') =>
            performRequest( method ? method:'put', UPDATE_FREE_LANCER_PROFILE.replace(":userId", body.userId), body, "agent" ),
        getSocialShareDetails: (body={}, method='get') =>
            performRequest( method ? method:'get',  GET_SOCIAL_SHARE_DETAILS,),


    }


}