import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_PAYMENT_REMINDER,
        GET_EXPIRED_INVOICE,
        GET_DASBOARD_GRAPH,
        GET_DASHBOARD_PAYTORESORT,
        GET_DASHBOARD_TODAYPICKUP,
        GET_DASHBOARD_CARDCOUNT,
        GET_DASHBOARD_CURRENTLIST,
        GET_DASHBOARD_UNCONFIRMED_PAY,
        GET_DASHBOARD_CARDS_INFO,
        GET_DASHBOARD_MONTHLY_REPORT,
        GET_DASHBOARD_MONTHLY_SALES_REPORT,
        GET_PROMOTION_INFO,
        GET_UPCOMING_BOOKING,
        GET_EXPIRING_BOOKING,
        GET_REPORT_INFO,
        GET_AGENTS_PAYOUT,
        GET_PAYOUT_HISTORY,
        GET_AGENT_REFERRAL_COMMISSION,
        GET_WALLET_PAYOUT
    } = URLS;
    return {
        getPaymentReminder: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_PAYMENT_REMINDER, body,"",null,date ),
        getCurrentList: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_DASHBOARD_CURRENTLIST, body,"",null,date ),
        getExpiredInvoice: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_EXPIRED_INVOICE, body,"",null,date ),
        getGraphInfo: (body={}, method='get',dateUrl) =>
         performRequest( method ? method:'get', GET_DASBOARD_GRAPH+dateUrl, body ),
        getPayToResort: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_DASHBOARD_PAYTORESORT, body,"",null,date ),
        getTodayPickup: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_DASHBOARD_TODAYPICKUP, body,"",null,date ),
        getCardInfo: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_DASHBOARD_CARDCOUNT, body,"",null,date ),
        getTodayBookingInfo: (body={}, method='get',date,dateUrl) =>
         performRequest( method ? method:'get', GET_DASHBOARD_CARDCOUNT+dateUrl, body,"",null,date),
        getUnconfirmedPay: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_DASHBOARD_UNCONFIRMED_PAY, body ),
        getDashCardsInfo: (body={}, method='get',date,url) =>
         performRequest( method ? method:'get', url ? url:GET_DASHBOARD_CARDS_INFO, body, "", null ,date ),
        getDashMonthlyInfo: (body={}, method='get',dateUrl) =>
         performRequest( method ? method:'get', GET_DASHBOARD_MONTHLY_REPORT+dateUrl, body), 
        getDashMonthlySalesInfo: (body={}, method='get',dateUrl) =>
         performRequest( method ? method:'get', GET_DASHBOARD_MONTHLY_SALES_REPORT+dateUrl, body),
        getPromotion: (body={}, method='get',extraUrl) =>
         performRequest( method ? method:'get', GET_PROMOTION_INFO+extraUrl, body,"travel"),
        getUpcomingBook: (body={}, method='get',extraUrl) =>
         performRequest( method ? method:'get', GET_UPCOMING_BOOKING, body,"travel"),
        getExpireBook: (body={}, method='get',extraUrl) =>
         performRequest( method ? method:'get', GET_EXPIRING_BOOKING, body,"travel"),
        getReportInfo: (body={}, method='get',year) =>
         performRequest( method ? method:'get', GET_REPORT_INFO.replace(":year",year), body,"travel"),
        getAgentPayoutComission: (body={}, method='get',year) =>
         performRequest( method ? method:'get', GET_AGENTS_PAYOUT.replace(":year", year), body, "travel"),
         getPayoutHistory: (body={}, method='get',year) =>
         performRequest( method ? method:'get', GET_PAYOUT_HISTORY.replace(":year", year), body, "travel"),
         getAgentReferralComission: (body={}, method='get',year) =>
         performRequest( method ? method:'get', GET_AGENT_REFERRAL_COMMISSION.replace(":year", year), body, "travel"),
         getWalletPayout: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_WALLET_PAYOUT, body, "travel"),
         updateWalletPayout: (body={}, method='post') =>
         performRequest( method ? method:'post', GET_WALLET_PAYOUT, body, "travel" )   
    }
}