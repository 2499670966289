import * as Types from '../../actions/type'
const initialState = {
    loading: false,
    loaded: false,
    data: [],
    params: null,
    allData: [],
}

const dummybookingEnquiries = {bookingEnquiries: [],
    page:1,
    pages:1,
    limit: 10,
    total: 0,
    allData: []
}

const getIndex = (arr, arr2, arr3, params = {}) => {
    if (arr2.length > 0) {
      let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
      let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
      let finalArr = [startIndex, endIndex]
      return (arr3 = finalArr)
    } else {
      let finalArr = [params.page, params.pages]
      return (arr3 = finalArr)
    }
}

export const bookingEnquiryDetails = ( state=initialState, action) =>{
    switch (action.type){
        case Types.GET_BOOKING_ENQUIRIES_DETAILS:
            return { ...state, loading: true };
        case Types.GET_BOOKING_ENQUIRIES_DETAILS_SUCCESS: 
        let newData = {
        }
        if(Object.keys(action.payload).length > 0){
            newData = { ...action.payload};
            newData.allData = [...action.payload.bookingEnquiries]
        }else{
            newData = {...dummybookingEnquiries}
        }
        newData.sortIndex = getIndex(newData.allData, state.data, state.sortIndex, {...newData})
        return { ...state, loading: false, loaded: true, payload: newData }
        case Types.GET_BOOKING_ENQUIRIES_DETAILS_FAILURE:
            return { ...state, loading: false, loaded: false, error: action.payload }
        case Types.ADD_BOOKING_ENQUIRIES_DATA: 
            let updatedData = { ...state.payload}
            updatedData.bookingEnquiries.unshift({...action.payload});
            updatedData.allData = [...updatedData.bookingEnquiries];
            updatedData.total = updatedData.bookingEnquiries.length;
            updatedData.sortIndex = getIndex(updatedData.bookingEnquiries, 
                                            updatedData.allData, 
                                            state.payload.sortIndex, 
                                            {...updatedData})
            return { ...state, loading: false, loaded: true, payload: updatedData}
        case Types.UPDATE_BOOKING_ENQUIRIES_DATA:
            let updatingData = { ...state.payload}
            
            let newbookingEnquiries = updatingData.bookingEnquiries.map((item) =>{
                if(item._id === action.payload._id){
                    item = {...action.payload}
                }
                return item
            })
            updatingData.bookingEnquiries = [...newbookingEnquiries];
            return {
                ...state, loading: false, loaded: true, payload: {...updatingData}
            }
        case Types.DELETE_BOOKING_ENQUIRIES_DATA:
            let deleteData = { ...state.payload}
            let deleteingbookingEnquiries = deleteData.bookingEnquiries.filter((item) =>{
                return item._id !== action.payload._id;
            })
            deleteData.bookingEnquiries = [...deleteingbookingEnquiries];
            deleteData.allData = [...deleteingbookingEnquiries];
            deleteData.total = deleteData.bookingEnquiries.length;
            deleteData.sortIndex = getIndex(deleteData.bookingEnquiries, 
                                            deleteData.allData, 
                                            state.payload.sortIndex, 
                                            {...deleteData})
            return {
                ...state, loading: false, loaded: true, payload: {...deleteData}
            }
        default: 
            return state
    }
}



