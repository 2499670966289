import * as Types from '../../actions/type'
const initialState = {
    loading: false,
    loaded: false,
    data: {},
    error:''
}



export const reservationDetails = ( state=initialState, action) =>{
    switch (action.type){
        case Types.GET_RESERVATION_DETAILS:
            return { ...state, loading: true };
        case Types.GET_RESERVATION_SUCCESS: 
                return { ...state, loading: false, loaded: true, data: action.payload }
        case Types.GET_RESERVATION_FAILURE:
                return { ...state, loading: false, loaded: false, error: action.payload }      
        default: 
            return state
    }
}



