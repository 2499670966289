import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { Auth0Provider } from "./authServices/auth0/auth0Service"
import config from "./authServices/auth0/auth0Config.json"
import { IntlProviderWrapper } from "./utility/context/Internationalization"
import { Layout } from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import { store } from "./redux/storeConfig/store"
import Spinner from "./components/@vuexy/spinner/Fallback-spinner"
// import "./assets/scss/css/layout.css";
import "./index.scss";
import "./assets/scss/css/form.css";

// import "./assets/scss/css/package.css";
// import "./assets/scss/css/booking.css";
// import "./assets/custom/css/room.css";
// import "./assets/scss/css/promocode.css";
import "./assets/scss/css/bookings/table.css";
import "./assets/scss/css/bookings/bookings.css";
// import "./assets/scss/css/Calendar-Week/Calendar.css";
// import "./assets/scss/css/Calendar/Calendar.css";
// import "./assets/scss/css/slick.css";
// import "./assets/scss/css/slick-theme.css";
import "./assets/scss/css/dashboard/table.css";
import "./assets/scss/css/dashboard/dashboard.css";
import "./assets/scss/css/app.css";
import "./assets/css/newapp.css"

const LazyApp = lazy(() => import("./App"))

// configureDatabase()

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin + process.env.REACT_APP_PUBLIC_PATH}>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <IntlProviderWrapper>
            <LazyApp />
          </IntlProviderWrapper>
        </Layout>
      </Suspense>
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
