import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    badge: "warning",
    
  },
  {
    id: "booking",
    title: "Bookings",
    type: "collapse",
    icon: <Icon.Grid size={16} />,
    children: [
      {
        id: "newbooking",
        title: "New Booking",
        type: "item",
        icon: <Icon.Mail size={16} />,
        navLink: "/bookings/create",
        permissions: ["admin", "editor"],
      },
      {
        id: "bookingList",
        title: "My Bookings",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/booking/listing",
        permissions: ["admin", "editor"],
      },
      {
        id: "unApprovedBookingList",
        title: "Awaiting Approvals",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/booking/unApprovedBookingList",
        permissions: ["admin", "editor"],
      }
    ],
  },
  {
    id: "promotion",
    title: "Promotions",
    type: "item",
    navLink: "/promotion",
    icon: <Icon.Home size={16} />,
   
  },
  {
    id: "myPromotion",
    title: "My Vouchers",
    type: "item",
    navLink: "/my-promotions",
    icon: <Icon.Home size={16} />,
   
  },
  {
    id: "documents",
    title: "Contract Rates",
    type: "item",
    navLink: "/documents",
    icon: <Icon.Home size={16} />,
   
  },
  {
    id: "referralPage",
    title: "Referral",
    type: "item",
    navLink: "/referral-page",
    icon: <Icon.Home size={16} />,
  },
  {
    id: "paymentHistory",
    title: "Payment History",
    type: "item",
    navLink: "/payment-history",
    icon: <Icon.Home size={16} />,
  },
  {
    id: "appsettings",
    title: "Settings",
    type: "collapse",
    icon: <Icon.Settings size={16} />,
    children: [
      {
        id: "myAddons",
        title: "My Addons",
        type: "item",
        navLink: "/activities/addon",
        icon: <Icon.Home size={16} />,       
      },
      {
        id: "Banners",
        title: "My Promotional Banner",
        type: "item",
        navLink: "/my-banners",
        icon: <Icon.Home size={16} />,       
      },
    ]
  },  
 ]


export default navigationConfig
