import * as Types from '../../actions/type'
const initialState = {
    loading: false,
    loaded: false,
    data: [],
    params: null,
    allData: [],
}

const dummyAdditionalCharges = {
    additionalCharges: [],
    page: 1,
    pages: 1,
    limit: 10,
    total: 0,
    allData: []
}

const getIndex = (arr, arr2, arr3, params = {}) => {
    if (arr2.length > 0) {
        let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
        let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
        let finalArr = [startIndex, endIndex]
        return (arr3 = finalArr)
    } else {
        let finalArr = [params.page, params.pages]
        return (arr3 = finalArr)
    }
}

export const additionalChargesDetails = (state = initialState, action) => {
    switch (action.type) {
        case Types.GET_ADDITIONAL_CHARGES_DETAILS:
            return { ...state, loading: true };
        case Types.GET_ADDITIONAL_CHARGES_DETAILS_SUCCESS:
            let newData = {}
            if (Object.keys(action.payload).length > 0) {
                newData = { ...action.payload };
                newData.allData = [...action.payload.additionalCharges]
            } else {
                newData = { ...dummyAdditionalCharges }
            }
            newData.sortIndex = getIndex(newData.allData, state.data, state.sortIndex, { ...newData })
            return { ...state, loading: false, loaded: true, payload: newData }
        case Types.GET_ADDITIONAL_CHARGES_DETAILS_FAILURE:
            return { ...state, loading: false, loaded: false, error: action.payload }
        case Types.ADD_ADDITIONAL_CHARGES_DATA:
            let updatedData = { ...state.payload }          
            updatedData.additionalCharges.unshift({ ...action.payload });
            updatedData.allData = [...updatedData.additionalCharges];
            updatedData.total = updatedData.additionalCharges.length;
            updatedData.sortIndex = getIndex(updatedData.additionalCharges,
                updatedData.allData,
                state.payload.sortIndex,
                { ...updatedData })
            return { ...state, loading: false, loaded: true, payload: updatedData }
        case Types.UPDATE_ADDITIONAL_CHARGES_DATA:
            let updatingData = { ...state.payload }

            let newAdditionalCharges = updatingData.additionalCharges.map((item) => {
                if (item._id === action.payload._id) {
                    item = { ...action.payload }
                }
                return item
            })
            updatingData.additionalCharges = [...newAdditionalCharges];
            return {
                ...state, loading: false, loaded: true, payload: { ...updatingData }
            }
        case Types.DELETE_ADDITIONAL_CHARGES_DATA:
            let deleteData = { ...state.payload }
            let deleteingAdditionalCharges = deleteData.additionalCharges.filter((item) => {
                return item._id !== action.payload._id;
            })
            deleteData.additionalCharges = [...deleteingAdditionalCharges];
            deleteData.allData = [...deleteingAdditionalCharges];
            deleteData.total = deleteData.additionalCharges.length;
            deleteData.sortIndex = getIndex(deleteData.additionalCharges,
                deleteData.allData,
                state.payload.sortIndex,
                { ...deleteData })
            return {
                ...state, loading: false, loaded: true, payload: { ...deleteData }
            }
        default:
            return state
    }
}



