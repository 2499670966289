/*USER DETAILS */
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const DELETE_USER_DATA = "DELETE_USER_DATA";

/*ROLE DETAILS */
export const GET_ROLE_DETAILS = "GET_ROLE_DETAILS";
export const GET_ROLE_DETAILS_SUCCESS = "GET_ROLE_DETAILS_SUCCESS";
export const GET_ROLE_DETAILS_FAILURE = "GET_ROLE_DETAILS_FAILURE";
export const ADD_ROLE_DATA = "ADD_ROLE_DATA";
export const UPDATE_ROLE_DATA = "UPDATE_ROLE_DATA";
export const DELETE_ROLE_DATA = "DELETE_ROLE_DATA";

/*WALLET DETAILS */
export const GET_WALLET_DETAILS = "GET_WALLET_DETAILS";
export const GET_WALLET_DETAILS_SUCCESS = "GET_WALLET_DETAILS_SUCCESS";
export const GET_WALLET_DETAILS_FAILURE = "GET_WALLET_DETAILS_FAILURE";

/*DEPARTMENT DETAILS */
export const GET_DEPARTMENT_DETAILS = "GET_DEPARTMENT_DETAILS";
export const GET_DEPARTMENT_DETAILS_SUCCESS = "GET_DEPARTMENT_DETAILS_SUCCESS";
export const GET_DEPARTMENT_DETAILS_FAILURE = "GET_DEPARTMENT_DETAILS_FAILURE";
export const ADD_DEPARTMENT_DATA = "ADD_DEPARTMENT_DATA";
export const UPDATE_DEPARTMENT_DATA = "UPDATE_DEPARTMENT_DATA";
export const DELETE_DEPARTMENT_DATA = "DELETE_DEPARTMENT_DATA";

/*BED DETAILS */
export const GET_BED_DETAILS = "GET_BED_DETAILS";
export const GET_BED_DETAILS_SUCCESS = "GET_BED_DETAILS_SUCCESS";
export const GET_BED_DETAILS_FAILURE = "GET_BED_DETAILS_FAILURE";
export const ADD_BED_DATA = "ADD_BED_DATA";
export const UPDATE_BED_DATA = "UPDATE_BED_DATA";
export const DELETE_BED_DATA = "DELETE_BED_DATA";

/*AMENEITIES DETAILS */
export const GET_AMENITY_DETAILS = "GET_AMENITY_DETAILS";
export const GET_AMENITY_DETAILS_SUCCESS = "GET_AMENITY_DETAILS_SUCCESS";
export const GET_AMENITY_DETAILS_FAILURE = "GET_AMENITY_DETAILS_FAILURE";
export const ADD_AMENITY_DATA = "ADD_AMENITY_DATA";
export const UPDATE_AMENITY_DATA = "UPDATE_AMENITY_DATA";
export const DELETE_AMENITY_DATA = "DELETE_AMENITY_DATA";



/* BUILDING DETAILS */
export const GET_BUILDING_DETAILS = "GET_BUILDING_DETAILS";
export const GET_BUILDING_DETAILS_SUCCESS = "GET_BUILDING_DETAILS_SUCCESS";
export const GET_BUILDING_DETAILS_FAILURE = "GET_BUILDING_DETAILS_FAILURE";
export const ADD_BUILDING_DATA = "ADD_BUILDING_DATA";
export const UPDATE_BUILDING_DATA = "UPDATE_BUILDING_DATA";
export const DELETE_BUILDING_DATA = "DELETE_BUILDING_DATA";


/* ROOMTYPE DETAILS */
export const GET_ROOMTYPE_DETAILS = "GET_ROOMTYPE_DETAILS";
export const GET_ROOMTYPE_DETAILS_SUCCESS = "GET_ROOMTYPE_DETAILS_SUCCESS";
export const GET_ROOMTYPE_DETAILS_FAILURE = "GET_ROOMTYPE_DETAILS_FAILURE";
export const ADD_ROOMTYPE_DATA = "ADD_ROOMTYPE_DATA";
export const UPDATE_ROOMTYPE_DATA = "UPDATE_ROOMTYPE_DATA";
export const DELETE_ROOMTYPE_DATA = "DELETE_ROOMTYPE_DATA";
/* ROOMNUMBER DETAILS */
export const GET_ROOMNUMBER_DETAILS = "GET_ROOMNUMBER_DETAILS";
export const GET_ROOMNUMBER_DETAILS_SUCCESS = "GET_ROOMNUMBER_DETAILS_SUCCESS";
export const GET_ROOMNUMBER_DETAILS_FAILURE = "GET_ROOMNUMBER_DETAILS_FAILURE";
export const ADD_ROOMNUMBER_DATA = "ADD_ROOMNUMBER_DATA";
export const UPDATE_ROOMNUMBER_DATA = "UPDATE_ROOMNUMBER_DATA";
export const DELETE_ROOMNUMBER_DATA = "DELETE_ROOMNUMBER_DATA";

/*COMPANIES DETAILS */
export const GET_COMPANIES_DETAILS = "GET_COMPANIES_DETAILS";
export const GET_COMPANIES_DETAILS_SUCCESS = "GET_COMPANIES_DETAILS_SUCCESS";
export const GET_COMPANIES_DETAILS_FAILURE = "GET_COMPANIES_DETAILS_FAILURE";
export const ADD_COMPANIES_DATA = "ADD_COMPANIES_DATA";
export const UPDATE_COMPANIES_DATA = "UPDATE_COMPANIES_DATA";
export const DELETE_COMPANIES_DATA = "DELETE_COMPANIES_DATA";

/*TRANSPORTATION CATEGORY DETAILS */
export const GET_TRANSPORTATION_CATEGORY_DETAILS = "GET_TRANSPORTATION_CATEGORY_DETAILS";
export const GET_TRANSPORTATION_CATEGORY_DETAILS_SUCCESS = "GET_TRANSPORTATION_CATEGORY_DETAILS_SUCCESS";
export const GET_TRANSPORTATION_CATEGORY_DETAILS_FAILURE = "GET_TRANSPORTATION_CATEGORY_DETAILS_FAILURE";
export const ADD_TRANSPORTATION_CATEGORY_DATA = "ADD_TRANSPORTATION_CATEGORY_DATA";
export const UPDATE_TRANSPORTATION_CATEGORY_DATA = "UPDATE_TRANSPORTATION_CATEGORY_DATA";
export const DELETE_TRANSPORTATION_CATEGORY_DATA = "DELETE_TRANSPORTATION_CATEGORY_DATA";

/*TRIPCOSTS DETAILS */
export const GET_TRIPCOST_DETAILS = "GET_TRIPCOST_DETAILS";
export const GET_TRIPCOST_DETAILS_SUCCESS = "GET_TRIPCOST_DETAILS_SUCCESS";
export const GET_TRIPCOST_DETAILS_FAILURE = "GET_TRIPCOST_DETAILS_FAILURE";
export const ADD_TRIPCOST_DATA = "ADD_TRIPCOST_DATA";
export const UPDATE_TRIPCOST_DATA = "UPDATE_TRIPCOST_DATA";
export const DELETE_TRIPCOST_DATA = "DELETE_TRIPCOST_DATA";

/*ADDON CATEGORY DETAILS */
export const GET_ADDONS_CATEGORY_DETAILS = "GET_ADDONS_CATEGORY_DETAILS";
export const GET_ADDONS_CATEGORY_DETAILS_SUCCESS = "GET_ADDONS_CATEGORY_DETAILS_SUCCESS";
export const GET_ADDONS_CATEGORY_DETAILS_FAILURE = "GET_ADDONS_CATEGORY_DETAILS_FAILURE";
export const ADD_ADDONS_CATEGORY_DATA = "ADD_ADDONS_CATEGORY_DATA";
export const UPDATE_ADDONS_CATEGORY_DATA = "UPDATE_ADDONS_CATEGORY_DATA";
export const DELETE_ADDONS_CATEGORY_DATA = "DELETE_ADDONS_CATEGORY_DATA";

/*ADDON SUB CATEGORY DETAILS */
export const GET_ADDONS_SUB_CATEGORY_DETAILS = "GET_ADDONS_SUB_CATEGORY_DETAILS";
export const GET_ADDONS_SUB_CATEGORY_DETAILS_SUCCESS = "GET_ADDONS_SUB_CATEGORY_DETAILS_SUCCESS";
export const GET_ADDONS_SUB_CATEGORY_DETAILS_FAILURE = "GET_ADDONS_SUB_CATEGORY_DETAILS_FAILURE";
export const ADD_ADDONS_SUB_CATEGORY_DATA = "ADD_ADDONS_SUB_CATEGORY_DATA";
export const UPDATE_ADDONS_SUB_CATEGORY_DATA = "UPDATE_ADDONS_SUB_CATEGORY_DATA";
export const DELETE_ADDONS_SUB_CATEGORY_DATA = "DELETE_ADDONS_SUB_CATEGORY_DATA";
/*TRANSPORTATION VEHICLE DETAILS */
export const GET_TRANSPORTATION_VEHICLE_DETAILS = "GET_TRANSPORTATION_VEHICLE_DETAILS";
export const GET_TRANSPORTATION_VEHICLE_DETAILS_SUCCESS = "GET_TRANSPORTATION_VEHICLE_DETAILS_SUCCESS";
export const GET_TRANSPORTATION_VEHICLE_DETAILS_FAILURE = "GET_TRANSPORTATION_VEHICLE_DETAILS_FAILURE";
export const ADD_TRANSPORTATION_VEHICLE_DATA = "ADD_TRANSPORTATION_VEHICLE_DATA";
export const UPDATE_TRANSPORTATION_VEHICLE_DATA = "UPDATE_TRANSPORTATION_VEHICLE_DATA";
export const DELETE_TRANSPORTATION_VEHICLE_DATA = "DELETE_TRANSPORTATION_VEHICLE_DATA";

/*DIVINGCOURSE CATEGORY DETAILS */
export const GET_DIVING_COURSE_DETAILS = "GET_DIVING_COURSE_DETAILS";
export const GET_DIVING_COURSE_DETAILS_SUCCESS = "GET_DIVING_COURSE_DETAILS_SUCCESS";
export const GET_DIVING_COURSE_DETAILS_FAILURE = "GET_DIVING_COURSE_DETAILS_FAILURE";
export const ADD_DIVING_COURSE_DATA = "ADD_DIVING_COURSE_DATA";
export const UPDATE_DIVING_COURSE_DATA = "UPDATE_DIVING_COURSE_DATA";
export const DELETE_DIVING_COURSE_DATA = "DELETE_DIVING_COURSE_DATA";

/*DIVINGDEPARTMENT CATEGORY DETAILS */
export const GET_DIVING_DEPARTMENT_DETAILS = "GET_DIVING_DEPARTMENT_DETAILS";
export const GET_DIVING_DEPARTMENT_DETAILS_SUCCESS = "GET_DIVING_DEPARTMENT_DETAILS_SUCCESS";
export const GET_DIVING_DEPARTMENT_DETAILS_FAILURE = "GET_DIVING_DEPARTMENT_DETAILS_FAILURE";
export const ADD_DIVING_DEPARTMENT_DATA = "ADD_DIVING_DEPARTMENT_DATA";
export const UPDATE_DIVING_DEPARTMENT_DATA = "UPDATE_DIVING_DEPARTMENT_DATA";
export const DELETE_DIVING_DEPARTMENT_DATA = "DELETE_DIVING_DEPARTMENT_DATA";
/*TRANSPORTATION LOCATIONS DETAILS */
export const GET_TRANSPORTATION_LOCATIONS_DETAILS = "GET_TRANSPORTATION_LOCATIONS_DETAILS";
export const GET_TRANSPORTATION_LOCATIONS_DETAILS_SUCCESS = "GET_TRANSPORTATION_LOCATIONS_DETAILS_SUCCESS";
export const GET_TRANSPORTATION_LOCATIONS_DETAILS_FAILURE = "GET_TRANSPORTATION_LOCATIONS_DETAILS_FAILURE";
export const ADD_TRANSPORTATION_LOCATIONS_DATA = "ADD_TRANSPORTATION_LOCATIONS_DATA";
export const UPDATE_TRANSPORTATION_LOCATIONS_DATA = "UPDATE_TRANSPORTATION_LOCATIONS_DATA";
export const DELETE_TRANSPORTATION_LOCATIONS_DATA = "DELETE_TRANSPORTATION_LOCATIONS_DATA";

/** Resort MEALS DETAILS */
export const GET_RESORT_MEALS_DETAILS = "GET_RESORT_MEALS_DETAILS";
export const GET_RESORT_MEALS_DETAILS_SUCCESS = "GET_RESORT_MEALS_DETAILS_SUCCESS";
export const GET_RESORT_MEALS_DETAILS_FAILURE = "GET_RESORT_MEALS_DETAILS_FAILURE";
export const ADD_RESORT_MEALS_DATA = "ADD_RESORT_MEALS_DATA";
export const UPDATE_RESORT_MEALS_DATA = "UPDATE_RESORT_MEALS_DATA";
export const DELETE_RESORT_MEALS_DATA = "DELETE_RESORT_MEALS_DATA";
export const GET_RESORT_RESTAURANTS_DATA = "GET_RESORT_RESTAURANTS_DATA";
/*TRANSPORTATION LOCATIONS DETAILS */
export const GET_ADDON_DETAILS = "GET_ADDON_DETAILS";
export const GET_ADDON_DETAILS_SUCCESS = "GET_ADDON_DETAILS_SUCCESS";
export const GET_ADDON_DETAILS_FAILURE = "GET_ADDON_DETAILS_FAILURE";
export const ADD_ADDON_DATA = "ADD_ADDON_DATA";
export const UPDATE_ADDON_DATA = "UPDATE_ADDON_DATA";
export const DELETE_ADDON_DATA = "DELETE_ADDON_DATA";

/* FACILITY DETAILS */
export const GET_FACILITY_DETAILS = "GET_FACILITY_DETAILS";
export const GET_FACILITY_DETAILS_SUCCESS = "GET_FACILITY_DETAILS_SUCCESS";
export const GET_FACILITY_DETAILS_FAILURE = "GET_FACILITY_DETAILS_FAILURE";
export const ADD_FACILITY_DATA = "ADD_FACILITY_DATA";
export const UPDATE_FACILITY_DATA = "UPDATE_FACILITY_DATA";
export const DELETE_FACILITY_DATA = "DELETE_FACILITY_DATA";
/*RESTURANTS LOCATIONS DETAILS */
export const GET_RESTURANTS_LOCATIONS_DETAILS = "GET_RESTURANTS_LOCATIONS_DETAILS";
export const GET_RESTURANTS_LOCATIONS_DETAILS_SUCCESS = "GET_RESTURANTS_LOCATIONS_DETAILS_SUCCESS";
export const GET_RESTURANTS_LOCATIONS_DETAILS_FAILURE = "GET_RESTURANTS_LOCATIONS_DETAILS_FAILURE";
export const ADD_RESTURANTS_LOCATIONS_DATA = "ADD_RESTURANTS_LOCATIONS_DATA";
export const UPDATE_RESTURANTS_LOCATIONS_DATA = "UPDATE_RESTURANTS_LOCATIONS_DATA";
export const DELETE_RESTURANTS_LOCATIONS_DATA = "DELETE_RESTURANTS_LOCATIONS_DATA";
/*TRAVEL AGENTS DETAILS */
export const GET_TRAVEL_AGENTS_DETAILS = "GET_TRAVEL_AGENTS_DETAILS";
export const GET_TRAVEL_AGENTS_DETAILS_SUCCESS = "GET_TRAVEL_AGENTS_DETAILS_SUCCESS";
export const GET_TRAVEL_AGENTS_DETAILS_FAILURE = "GET_TRAVEL_AGENTS_DETAILS_FAILURE";
export const ADD_TRAVEL_AGENTS_DATA = "ADD_TRAVEL_AGENTS_DATA";
export const UPDATE_TRAVEL_AGENTS_DATA = "UPDATE_TRAVEL_AGENTS_DATA";
export const DELETE_TRAVEL_AGENTS_DATA = "DELETE_TRAVEL_AGENTS_DATA";


/* SURCHARGES DETAILS */
export const GET_SURCHARGES_DETAILS = "GET_SURCHARGES_DETAILS";
export const GET_SURCHARGES_DETAILS_SUCCESS = "GET_SURCHARGES_DETAILS_SUCCESS";
export const GET_SURCHARGES_DETAILS_FAILURE = "GET_SURCHARGES_DETAILS_FAILURE";
export const ADD_SURCHARGES_DATA = "ADD_SURCHARGES_DATA";
export const UPDATE_SURCHARGES_DATA = "UPDATE_SURCHARGES_DATA";
export const DELETE_SURCHARGES_DATA = "DELETE_SURCHARGES_DATA";
/* TAXES DETAILS */

export const GET_CHARGES_TAXES_DETAILS = "GET_CHARGES_TAXES_DETAILS";
export const GET_CHARGES_TAXES_DETAILS_SUCCESS = "GET_CHARGES_TAXES_DETAILS_SUCCESS";
export const GET_CHARGES_TAXES_DETAILS_FAILURE = "GET_CHARGES_TAXES_DETAILS_FAILURE";
export const GET_CHARGES_COMPANIES_DATA = "GET_CHARGES_COMPANIES_DATA";

/* PACKAGE DETAILS */

export const GET_PACKAGE_DETAILS = "GET_PACKAGE_DETAILS";
export const GET_PACKAGE_SUCCESS = "GET_PACKAGE_SUCCESS";
export const GET_PACKAGE_FAILURE = "GET_PACKAGE_FAILURE";
export const GET_PACKAGE_TRIPCOST = "GET_PACKAGE_TRIPCOST";
export const GET_PACKAGE_ADDONS = "GET_PACKAGE_ADDONS";
export const GET_PACKAGE_ROOMTYPE = "GET_PACKAGE_ROOMTYPE";
export const GET_PACKAGE_BOOKINFO = "GET_PACKAGE_BOOKINFO";
export const GET_PACKAGE_BOOKINFO_CLEAR = "GET_PACKAGE_BOOKINFO_CLEAR";
export const GET_PACKAGE_SURCHARGE = "GET_PACKAGE_SURCHARGE";
export const GET_PACKAGE_GROUP = "GET_PACKAGE_GROUP";
export const GET_ACTIVE_AGENT = "GET_ACTIVE_AGENT";

export const GET_CURRENCY_DETAILS = "GET_CURRENCY_DETAILS";
export const GET_CURRENCY_DETAILS_SUCCESS = "GET_CURRENCY_DETAILS_SUCCESS";
export const GET_CURRENCY_DETAILS_FAILURE = "GET_CURRENCY_DETAILS_FAILURE";


/* ADDITIONAL CHARGES DETAILS */
export const GET_ADDITIONAL_CHARGES_DETAILS = "GET_ADDITIONAL_CHARGES_DETAILS";
export const GET_ADDITIONAL_CHARGES_DETAILS_SUCCESS = "GET_ADDITIONAL_CHARGES_DETAILS_SUCCESS";
export const GET_ADDITIONAL_CHARGES_DETAILS_FAILURE = "GET_ADDITIONAL_CHARGES_DETAILS_FAILURE";
export const ADD_ADDITIONAL_CHARGES_DATA = "ADD_ADDITIONAL_CHARGES_DATA";
export const UPDATE_ADDITIONAL_CHARGES_DATA = "UPDATE_ADDITIONAL_CHARGES_DATA";
export const DELETE_ADDITIONAL_CHARGES_DATA = "DELETE_ADDITIONAL_CHARGES_DATA";
export const ADD_CURRENCY_DATA ="ADD_CURRENCY_DATA";
export const UPDATE_CURRENCY_DATA ="UPDATE_CURRENCY_DATA";
export const DELETE_CURRENCY_DATA="DELETE_CURRENCY_DATA";

export const GET_BOOKING_ENQUIRIES_DETAILS = "GET_BOOKING_ENQUIRIES_DETAILS";
export const GET_BOOKING_ENQUIRIES_DETAILS_SUCCESS = "GET_BOOKING_ENQUIRIES_DETAILS_SUCCESS";
export const GET_BOOKING_ENQUIRIES_DETAILS_FAILURE = "GET_BOOKING_ENQUIRIES_DETAILS_FAILURE";
export const ADD_BOOKING_ENQUIRIES_DATA ="ADD_BOOKING_ENQUIRIES_DATA";
export const UPDATE_BOOKING_ENQUIRIES_DATA ="UPDATE_BOOKING_ENQUIRIES_DATA";
export const DELETE_BOOKING_ENQUIRIES_DATA="DELETE_BOOKING_ENQUIRIES_DATA";

export const GET_BOOKING_SETTING_DETAILS = "GET_BOOKING_SETTING_DETAILS";
export const GET_BOOKING_SETTING_DETAILS_SUCCESS = "GET_BOOKING_SETTING_DETAILS_SUCCESS";
export const GET_BOOKING_SETTING_DETAILS_FAILURE = "GET_BOOKING_SETTING_DETAILS_FAILURE";
export const ADD_BOOKING_SETTING_DATA ="ADD_BOOKING_SETTING_DATA";
export const UPDATE_BOOKING_SETTING_DATA ="UPDATE_BOOKING_SETTING_DATA";
export const DELETE_BOOKING_SETTING_DATA="DELETE_BOOKING_SETTING_DATA";

export const GET_TRAVEL_AGENTS_REGISTRATION_DETAILS="GET_TRAVEL_AGENTS_REGISTRATION_DETAILS";
export const GET_TRAVEL_AGENTS_REGISTRATION__DETAILS_SUCCESS="GET_TRAVEL_AGENTS_REGISTRATION__DETAILS_SUCCESS";
export const GET_TRAVEL_AGENTS_REGISTRATION_DETAILS_FAILURE="GET_TRAVEL_AGENTS_REGISTRATION_DETAILS_FAILURE";
export const ADD_TRAVEL_AGENTS_REGISTRATION_DATA="ADD_TRAVEL_AGENTS_REGISTRATION_DATA";
export const UPDATE_TRAVEL_AGENTS_REGISTRATION_DATA="UPDATE_TRAVEL_AGENTS_REGISTRATION_DATA";
export const DELETE_TRAVEL_AGENTS_REGISTRATION_DATA="DELETE_TRAVEL_AGENTS_REGISTRATION_DATA";
/*BOOKINGLISTING*/
export const GET_BOOKING_LISTING_DETAILS="GET_BOOKING_LISTING_DETAILS";
export const GET_BOOKING_LISTING_DETAILS_SUCCESS="GET_BOOKING_LISTING_DETAILS_SUCCESS";
export const GET_BOOKING_LISTING_DETAILS_FAILURE="GET_BOOKING_LISTING_DETAILS_FAILURE";

/*RESERVATION*/

export const GET_RESERVATION_DETAILS="GET_RESERVATION_DETAILS";
export const GET_RESERVATION_SUCCESS="GET_RESERVATION_SUCCESS";
export const GET_RESERVATION_FAILURE="GET_RESERVATION_FAILURE";


/*DASHBOARD*/

export const GET_PAYMENT_REMINDER="GET_PAYMENT_REMINDER";
export const GET_EXPIRED_INVOICE="GET_EXPIRED_INVOICE";
export const GET_DASHBOARD_GRAPH="GET_DASHBOARD_GRAPH";
export const GET_DASHBOARD_GRAPH_CHECKIN = "GET_DASHBOARD_GRAPH_CHECKIN";
export const GET_DASHBOARD_PAYATRESORT="GET_DASHBOARD_PAYATRESORT";
export const GET_DASHBOARD_TODAYPICKUP="GET_DASHBOARD_TODAYPICKUP";
export const GET_DASHBOARD_CARDINFO="GET_DASHBOARD_CARDINFO";
export const GET_DASHBOARD_CARDINFO_TYPE="GET_DASHBOARD_CARDINFO_TYPE";
export const GET_DASHBOARD_CURRENT_LIST="GET_DASHBOARD_CURRENT_LIST";
export const GET_DASHBOARD_UNCONFIRMED_PAY="GET_DASHBOARD_UNCONFIRMED_PAY";


export const DASHBOARD_LOADING="DASHBOARD_LOADING";
export const DASHBOARD_FAILURE="DASHBOARD_FAILURE";

/*LEAVE PROCESS*/
export const GET_LEAVE_INFO ="GET_LEAVE_INFO";
export const GET_LEAVE_SUCCESS ="GET_LEAVE_SUCCESS";
export const GET_LEAVE_FAILURE ="GET_LEAVE_FAILURE";

// UN APPROVE BOOKING LISTS

export const GET_UN_APPROVE_BOOKING_LISTING_DETAILS ="GET_UN_APPROVE_BOOKING_LISTING_DETAILS";
export const GET_UN_APPROVE_BOOKING_LISTING_DETAILS_SUCCESS ="GET_UN_APPROVE_BOOKING_LISTING_DETAILS_SUCCESS";
export const GET_UN_APPROVE_BOOKING_LISTING_DETAILS_FAILURE ="GET_UN_APPROVE_BOOKING_LISTING_DETAILS_FAILURE";