import demoLogo from "./logo/logo.png";
import summerbaylogo from "../assets/img/logo/sbay-app-logo.png"

let  logo = demoLogo

let applicationName = "Travoxios Digital Intelligence Platform (TDIP)"

let siteName = "https://www.summerbayresort.com.my/"

let FooterName = "Travoxios (1165017-V)"

if(process.env.REACT_APP_PRODUCTION_MODE !== "STAGE"){
    applicationName = "Summerbay Digital Intelligence Platform (SBDIP)"
    logo = summerbaylogo
    FooterName = " Summer Bay Travels & Tours Sdn Bhd (1165017-V)"
}

export {
    logo,
    applicationName,
    siteName,
    FooterName
}
