import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_PACKAGE_DETAILS, 
            ADD_PACKAGE_DETAILS,
            UPDATE_PACKAGE_DETAILS,
            DELETE_PACKAGE_DETAILS,
            GET_ADDONS_BY_CATEGORIES,
            ADD_PACKAGE_BOOKPERIOD_DETAILS,
            DUPLICATE_PACKAGE_DETAILS,
            GET_SURCHARGES_DETAILS,
            ADD_NEW_PACKAGE_GROUP,
            ADD_ACTIVE_TRAVELAGENT
         } = URLS;
    return {
         getPackageDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_PACKAGE_DETAILS, body ),
         getAddonsBy: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_ADDONS_BY_CATEGORIES, body ),
         addPackageDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_PACKAGE_DETAILS, body ),
         getGroupPackageInfo: (body={}, method='get') =>
         performRequest( method ? method:'get', ADD_NEW_PACKAGE_GROUP, body ),
         getActiveAgents: (body={}, method='get') =>
         performRequest( method ? method:'get', ADD_ACTIVE_TRAVELAGENT, body ),
         addGroupPackage: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_NEW_PACKAGE_GROUP, body ),
         updatePackageDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_PACKAGE_DETAILS, body ),
         addBookingPeriods: (body={}, method='post',id) =>
         performRequest( method ? method:'post', ADD_PACKAGE_BOOKPERIOD_DETAILS, body,'',id ),
         updateBookingPeriods: (body={}, method='put',id) =>
         performRequest( method ? method:'put', ADD_PACKAGE_BOOKPERIOD_DETAILS, body,'',id ),
         getBookingPeriods: (body={}, method='get',id) =>
         performRequest( method ? method:'get', ADD_PACKAGE_BOOKPERIOD_DETAILS, body,'',id ),
         updatePackageDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_PACKAGE_DETAILS, body ),
         deletePackageDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_PACKAGE_DETAILS, body ),
         duplicatePackage: (body={}, method='post') =>
         performRequest( method ? method:'post', DUPLICATE_PACKAGE_DETAILS, body ),
         getSurchargesDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_SURCHARGES_DETAILS, body ),
    }
}