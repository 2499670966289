import { combineReducers } from "redux";

import { roomTypeDetails } from "./reducer.roomTypes"

const roomTypeReducers = combineReducers({

    roomTypeDetails

})
export default roomTypeReducers;
