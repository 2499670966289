export const generateAppConfig = () =>{
    let exportResult = {
        appRepoLocation:"https://app-test.summerbayresort.com.my",
        backendUrl: "http://localhost:3100",
        agentBackendUrl: "http://localhost:4041",
        jsReportUrl: "http://localhost:5488"
    };
    if(process.env.REACT_APP_PRODUCTION_MODE ==='TEST'){
        exportResult.appRepoLocation = "https://app-test.summerbayresort.com.my";
        exportResult.backendUrl = "https://api-test.summerbayresort.com.my";
        exportResult.agentBackendUrl = "https://api-business-test.summerbayresort.com.my";
        exportResult.userPortalBackendUrl = "https://api-app-test.summerbayresort.com.my";
        exportResult.jsReportUrl= "https://report.summerbayresort.com.my";
    }
    if(process.env.REACT_APP_PRODUCTION_MODE ==='STAGE'){
        exportResult.appRepoLocation = "https://app.travoxis.com";
        exportResult.backendUrl = "https://admin-api.travoxis.com";
        exportResult.agentBackendUrl = "https://business-api.travoxis.com";
        exportResult.userPortalBackendUrl = "https://app-api.travoxis.com";
        exportResult.jsReportUrl = "https://report.travoxis.com"
    }
    if(process.env.REACT_APP_PRODUCTION_MODE ==='PROD'){
        exportResult.appRepoLocation = "https://app.summerbayresort.com.my";
        exportResult.backendUrl = "https://api.summerbayresort.com.my";
        exportResult.agentBackendUrl = "https://api.business.summerbayresort.com.my";
        exportResult.userPortalBackendUrl = "https://api.app.summerbayresort.com.my";
        exportResult.jsReportUrl = "https://report.summerbayresort.com.my"
    }
    return exportResult;
}