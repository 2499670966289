import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_TRIPCOST_DETAILS, ADD_TRIPCOST_DETAILS, UPDATE_TRIPCOST_DETAILS, DELETE_TRIPCOST_DETAILS, GET_LOCATION } = URLS;
    return {
        getTripcostDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_TRIPCOST_DETAILS, body),
        addTripcostDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_TRIPCOST_DETAILS, body),
        updateTripcostDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_TRIPCOST_DETAILS, body),
        deleteTripcostDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_TRIPCOST_DETAILS, body),

        getLocationDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_LOCATION, body),
    }
}
