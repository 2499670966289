import { updateData } from '../../actions/buildings/action.buildings'
import * as Types from '../../actions/type'
const initialState = {
    loading: false,
    loaded: false,
    data: [],
    params: null,
    allData: []
}
const dummyBuildings = {building: [],
    page:1,
    pages:1,
    limit: 10,
    total: 0,
    allData: []
}

const getIndex = (arr, arr2, arr3, params = {}) => {
    if (arr2.length > 0) {
        let startIndex = arr.findIndex(i => i._id === arr2[0]._id) + 1
        let endIndex = arr.findIndex(i => i._id === arr2[arr2.length - 1]._id) + 1
        let finalArr = [startIndex, endIndex]
        return (arr3 = finalArr)
    } else {
        let finalArr = [params.page, params.pages]
        return (arr3 = finalArr)
    }
}

export const buildingsDetails = (state = initialState, action) => {
    switch (action.type) {
        case Types.GET_BUILDING_DETAILS:
            return { ...state, loading: true };
        case Types.GET_BUILDING_DETAILS_SUCCESS:
            let newData = {
            }
            if(Object.keys(action.payload).length > 0){
                newData = { ...action.payload};
                newData.allData = [...action.payload.building]
            }else{
                newData = {...dummyBuildings}
            }
            newData.sortIndex = getIndex(newData.allData, state.data, state.sortIndex, {...newData})
            return { ...state, loading: false, loaded: true, payload: newData }
        case Types.GET_BUILDING_DETAILS_FAILURE:
            return { ...state, loading: false, loaded: false, error: action.payload }
        case Types.ADD_BUILDING_DATA:
            let updatedData = { ...state.payload }
            updatedData.building.unshift({ ...action.payload });
            updatedData.allData = [...updatedData.building];
            updatedData.total = updatedData.building.length;
            updatedData.sortIndex = getIndex(updatedData.building,
                updatedData.allData,
                state.payload.sortIndex,
                { ...updatedData })
            return { ...state, loading: false, loaded: true, payload: updatedData }
        case Types.UPDATE_BUILDING_DATA:
            let updatingData = { ...state.payload }

            let newBuildings = updatingData.building.map((item) => {
                if (item._id === action.payload._id) {
                    item = { ...action.payload }
                }
                return item
            })
            updatingData.building = [...newBuildings];
            return {
                ...state, loading: false, loaded: true, payload: { ...updatingData }
            }
        case Types.DELETE_BUILDING_DATA:
            let deleteData = { ...state.payload }
            let deleteingBuildings = deleteData.building.filter((item) => {
                return item._id !== action.payload._id;
            })
            deleteData.building = [...deleteingBuildings];
            deleteData.allData = [...deleteingBuildings];
            deleteData.total = deleteData.building.length;
            deleteData.sortIndex = getIndex(deleteData.building,
                deleteData.allData,
                state.payload.sortIndex,
                { ...deleteData })
            return {
                ...state, loading: false, loaded: true, payload: { ...deleteData }
            }
        default:
            return state
    }
}