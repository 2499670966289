import { performRequest } from './api-handler';
import { URLS } from '../utility/urls';
export default () => {
    const { GET_PRMOTIONS,
        GET_PROMOCODE,
        GET_PROMOLIST,
        GET_PACKAGES_BY_ID,
        GET_ROOM_TYPES_ID,
        GET_USER_BOUGHT_PACKAGES,
        GET_INFLUENCER_PACKAGE,
        GET_ADDITIONAL_CHARGES,
        GET_PACKAGE_PERIODS_BY_PACKAGE_ID,
        GET_SURCHARGES,
        GET_SURCHARGES_CALENDER_DETAILS,
        GET_USER_BOUGHT_VOUCHORS,
        GET_AGENT_EVENTS,
        UPDATE_MEMBER_NOTES,
        GET_EVENTS,
        GET_EVENT_VACANT } = URLS;
    return {
        getPromotions: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_PRMOTIONS, body, "user"),
        getPackagesById: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_PACKAGES_BY_ID.replace(":_id", body.id), body, "user"),
        getRoomTypesId: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_ROOM_TYPES_ID.replace(":_id", body.id), body, "userPortal"),
        getPromoCode: (body = {}, method = 'get', type="cash") =>
            performRequest(method ? method : 'get', `${GET_PROMOCODE}?type=${type}`, body, "user"),
        getPromoCodeDetails: (body={}, method='get', url) =>
            performRequest( method ? method:'get', url ||GET_PROMOLIST, body ),
        getuserBoughtPackages: (body={}, method='get', url) =>
            performRequest( method ? method:'get',GET_USER_BOUGHT_PACKAGES, body, "user" ),
        getUserVouchers: (body={}, method='get', url) =>
            performRequest( method ? method:'get',GET_USER_BOUGHT_VOUCHORS, body, "user" ),
        getInfluencerPackage: (body={}, method='get') =>
            performRequest( method ? method:'get',GET_INFLUENCER_PACKAGE, body, "user" ),
        getAdditionalCharges: (body={}, method='get') =>
            performRequest( method ? method:'get',GET_ADDITIONAL_CHARGES, body, "user"),
        getPackagePeriodsByPackageId: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_PACKAGE_PERIODS_BY_PACKAGE_ID.replace(":id", body), body, "user"),
        getSurcharges: (body={}, method='get') =>
            performRequest( method ? method:'get',GET_SURCHARGES, body, "user"),
        getSurchargeCalenderDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_SURCHARGES_CALENDER_DETAILS, body, 'user'),
        getEvents: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_EVENTS, body),
        getEventVacant: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_EVENT_VACANT, body),
        getAgentEvents: (body = {}, method = 'get', agentId) =>
            performRequest(method ? method : 'get', GET_AGENT_EVENTS.replace(':agentId', agentId), body, 'user'),
        updateMemberNotes: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', UPDATE_MEMBER_NOTES, body, 'user'),
    }
}